<template>
    <div>
        <Notification />
        <div id="app-login">
            <img src="../assets/gsi-full.png">
            <h2>Sign in to P2020</h2>
            <form @submit.prevent="handleSubmit">
                <div class="form-group">
                    <label for="username">Username</label>
                    <input type="text" v-model="username" name="username" class="form-control" :class="{ 'is-invalid': submitted && !username }" />
                    <div v-show="submitted && !username" class="invalid-feedback">Username is required</div>
                </div>
                <div class="form-group">
                    <label htmlFor="password">Password</label>
                    <input type="password" v-model="password" name="password" class="form-control" :class="{ 'is-invalid': submitted && !password }" />
                    <div v-show="submitted && !password" class="invalid-feedback">Password is required</div>
                </div>
                <div class="form-group">
                    <button class="btn btn-primary app-button primary-button" :class="{'button-loading': submitted}">
                        <span v-if="!this.submitted">Login</span>
                        <font-awesome-icon v-if="this.submitted" :icon="['fas', 'spinner']" class="loading-login"/>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Notification from '../components/Generics/Notification.vue';

export default {
  components: { Notification },
    data () {
        return {
            username: '',
            password: '',
            submitted: false
        }
    },
    created () {
        // reset login status
        this.logout();
        this.resetNotification();
    },
    computed: {
        ...mapGetters({
            redirectUrl: "redirectUrl",
        })
    },
    methods: {
        ...mapActions(['authenticate', 'logout', 'resetNotification', 'storeRedirectURL', 'wipeRedirectURL']),
        async handleSubmit (e) {
            this.submitted = true;
            const { username, password } = this;
            if (username && password) {
                await this.authenticate({"username": username, "password": password})
                // check for redirect url and plug in if exists, otherwise just go to project overview
                if(this.redirectUrl!==undefined){
                    this.$router.push({path: `/${this.redirectUrl}`})
                    this.wipeRedirectURL()
                }
                else{
                    this.$router.push('Projects')
                }
            }
            this.submitted = false
        }
    }
};
</script>
<style scoped>
#app-login{
  padding:3rem 2rem;
  max-width: 90%;
  text-align: center;
  border: 1px solid grey;
  margin:auto;
  box-shadow:0.1rem 0.1rem;
}

#app-login input{
    max-width: 75%;
}

img{
    height: 10rem;
    width: 20rem;
}

.form-group{
    padding:0.5rem;
    line-height: 1.5;
}

.form-group label{
    padding-right: 0.5rem;
}

.login-class{
    padding-top:20rem
}

.app-button{
    width:15rem;
    border-radius: 5px;
}

.button-loading{
    background-color:rgba(rgba(23, 143, 43, 0.123));
}
</style>